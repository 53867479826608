import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "../components/Button";
import {
  airpayRechargeRequest,
  payIn,
  payInCallback,
  serviceRequest,
  serviceRequestStatus,
} from "../urls";
import API from "../components/api2";
import { connect, useDispatch } from "react-redux";
import { changePage } from "../redux/slice/dashboardSlice";
import { setSR } from "../redux/slice/paymentSlice";
import Swal from "sweetalert2";
import Header from "../components/Header";
import Modal from "react-modal";
import { airpay, razorpay } from "../images";
import loaderGif from "../gif/buffer.gif";
import { useNavigate } from "react-router-dom";

const Payment = ({ backPage, merchant_id, srNo, amount }) => {
  const [paymentMode, setPaymentMode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [proceedToPayDisabled, setProceedToPayDisabled] = useState(true);
  const [showTotal, setShowTotal] = useState(false);
  const [showPaymentMode, setShowPaymentMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [airpayForm, setAirpayForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    dispatch(
      changePage({
        page: backPage,
      })
    );
    dispatch(
      setSR({
        srNo: "",
      })
    );
  };

  useEffect(() => {
    if (["", null, 0, undefined].includes(srNo)) {
      raiseServiceRequest();
    } else {
      fetchServiceRequestStatus();
      console.log("elsey");
    }
  }, []);

  useEffect(() => {
    fetchAirpayForm();
  }, [amount, paymentMode]);

  const raiseServiceRequest = () => {
    if (amount) {
      const requestBody = {
        transaction_amount: amount,
        request_type: "wallet_recharge",
      };

      API({
        ...serviceRequest,
        body: requestBody,
        onSuccess: (res) => {
          console.log("Wallet Recharge Request:", res);
          // setSrNo(res.data.data.sr_no);
          dispatch(
            setSR({
              srNo: res.data.data.sr_no,
            })
          );
        },
        onError: (error) => {
          console.error("Error Wallet Recharge Request: ", error);
        },
      });
    } else {
      console.error("Recharge Amount is missing.");
    }
  };

  const fetchServiceRequestStatus = () => {
    if (amount) {
      const requestBody = {
        srNo: srNo,
      };

      API({
        ...serviceRequestStatus,
        params: requestBody,
        onSuccess: (res) => {
          var data = res.data.data;
          if (Object.keys(data).length !== 0) {
            const title =
              data.status === "200"
                ? `<strong style="font-size: 35px;">Payment Successfull</strong><br><span style="font-size: 22px;">Transaction ID: ${data.tran_id}</span>`
                : `<strong style="font-size: 28px;">${data.reason}</strong><br><span style="font-size: 22px;">Transaction ID: ${data.tran_id}</span>`;

            Swal.fire({
              icon: data.status === "400" ? "error" : "success",
              title: title,
              preConfirm: () => {
                handleBack();
              },
            });
          } else {
            raiseServiceRequest();
          }
        },
        onError: (error) => {
          raiseServiceRequest();
        },
      });
    } else {
      console.error("Recharge Amount is missing.");
    }
  };

  const fetchAirpayForm = () => {
    if (
      amount &&
      paymentMode === "Online"
      // selectedPaymentMethod === "airpay"
    ) {
      const requestBody = {
        recharge_amount: amount,
        sr_no: srNo,
      };

      API({
        ...airpayRechargeRequest,
        body: requestBody,
        onSuccess: (res) => {
          console.log("Airpay Recharge Request:", res);
          setAirpayForm(res.data.data);
        },
        onError: (error) => {
          console.error("Error Airpay Recharge Request: ", error);
        },
      });
    }
  };

  const initiatePayment = () => {
    setLoading(true);
    const transactionForm = document.getElementById("main-transaction-form");
    if (transactionForm) {
      setTimeout(() => {
        setLoading(false);
        transactionForm.submit();
      }, 2000);
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Contact to admin",
        text: "The payment form could not be found. Please contact support.",
      });
    }
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
    setErrorMessage("");
  };

  const handleDone = () => {
    if (paymentMode === "") {
      setErrorMessage("Please select any payment mode!");
      setProceedToPayDisabled(true);
    } else {
      setErrorMessage("");
      setProceedToPayDisabled(false);
      setShowTotal(true);
      setShowPaymentMode(true);
    }
  };

  // razorpay payIn
  const handlePaymentSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    if (paymentMethod === "airpay") {
      initiatePayment();
    }
    if (paymentMethod === "razorpay") {
      handleRazorpayPayment();
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined" && !window.Razorpay) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => console.log("Razorpay script loaded successfully");
      script.onerror = () => console.error("Failed to load Razorpay script");
      document.body.appendChild(script);
    }
  }, []);

  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => reject("Failed to load Razorpay script");
      document.body.appendChild(script);
    });
  };

  const handleRazorpayPayment = () => {
    setLoading(true);
    API({
      ...payIn,
      body: { amount: parseInt(amount, 10), sr_no: srNo },
      onSuccess: (res) => {
        setLoading(false);
        const options = res?.data?.data?.options;
        initializeRazorpay(options);
      },
      onError: (error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: `${error.response.data.message}`,
        });
      },
    });
  };

  const initializeRazorpay = (responseOptions) => {
    loadRazorpayScript()
      .then(() => {
        const options = {
          key: responseOptions.key,
          amount: responseOptions.amount,
          currency: responseOptions.currency,
          name: responseOptions.name,
          description: responseOptions.description,
          image: responseOptions.image,
          order_id: responseOptions.order_id,
          callback_url: responseOptions.callback_url,
          prefill: {
            name: responseOptions.prefill.name,
            email: responseOptions.prefill.email,
            contact: responseOptions.prefill.contact,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: responseOptions.color,
          },
          handler: function (response) {
            setLoader(true);
            API({
              ...payInCallback,
              body: response,
              onSuccess: (res) => {
                setLoader(false);
                setIsModalOpen(false);
                Swal.fire({
                  icon: "success",
                  title: `${res.data.message}`,
                }).then(() => {
                  changePageFunc();
                });
              },
              onError: (error) => {
                setLoader(false);
                Swal.fire({
                  icon: "error",
                  title: `${error.response.data.message}`,
                });
              },
            });
          },
        };

        const rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          console.error("Payment failed:", response.error);
        });

        rzp1.open();
      })
      .catch((error) => {
        console.error("Error loading Razorpay script:", error);
      });
  };

  const changePageFunc = () => {
    dispatch(
      changePage({
        page: "home",
      })
    );

    navigate("/");
  };

  return (
    <>
      <Header />
      <div
        className="container-fluid bg-white d-flex align-items-center my-2"
        style={{ height: "100%" }}
      >
        {loader ? (
          <img
            src={loaderGif}
            alt="Loading..."
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%)",
            }}
          />
        ) : (
          <>
            <div className="row px-3 flex-grow-1 h-100 py-3">
              <div
                className="col-7"
                style={{ borderRight: "1px solid rgba(105, 102, 102, 1)" }}
              >
                <h2
                  className="mt-3 login_text"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "rgba(26, 25, 24, 1)",
                  }}
                >
                  Recharge Wallet
                </h2>
                <div className="px-3">
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    className="mb-0 mt-5 trans_text"
                  >
                    {"Merchant ID"} : {merchant_id}
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    className="mb-0 mt-3 trans_text"
                  >
                    {"Service Request No."} : {srNo}
                  </p>
                  <p
                    style={{
                      fontFamily: "Montserrat",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                    className="mb-0 mt-3 trans_text"
                  >
                    Amount - {amount}
                  </p>
                  <div className="d-flex align-items-center mt-3 position-relative">
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                      className="mb-0 trans_text"
                    >
                      Payment mode -
                    </p>
                    <Form.Group>
                      <div className="d-flex align-items-center justify-content-between ps-4">
                        <Form.Check
                          className="mb-0 trans_text"
                          type="radio"
                          label="Cash"
                          name="paymentMode"
                          value="Cash"
                          checked={paymentMode === "Cash"}
                          onChange={handlePaymentModeChange}
                          id="cash-radio"
                        />
                        <Form.Check
                          className="ms-3 mb-0 trans_text"
                          type="radio"
                          label="Online"
                          name="paymentMode"
                          value="Online"
                          checked={paymentMode === "Online"}
                          onChange={handlePaymentModeChange}
                          id="online-radio"
                        />
                      </div>
                    </Form.Group>
                    {errorMessage && (
                      <p
                        className="mb-0 position-absolute mt-5"
                        style={{ color: "red", left: "21%" }}
                      >
                        {errorMessage}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{ marginTop: "200px" }}
                  className="d-flex justify-content-between"
                >
                  <Button children={"Back"} onClick={handleBack} />
                  <Button
                    disabled={!srNo}
                    style={{
                      opacity: !srNo ? 0.5 : 1,
                      cursor: !srNo ? "not-allowed" : "pointer",
                    }}
                    children={"Done"}
                    onClick={handleDone}
                  />
                </div>
              </div>
              <div className="col-5" style={{ flexGrow: "1" }}>
                <div
                  className="d-flex flex-column px-3 py-4 h-100"
                  style={{
                    backgroundColor: "rgba(22, 64, 127, 1)",
                    borderRadius: "5px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 login_text"
                    >
                      Payment Mode
                    </p>
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 login_text"
                    >
                      {showPaymentMode ? paymentMode : "None"}
                    </p>
                  </div>
                  <div
                    style={{ borderBottom: "1.5px solid white" }}
                    className="d-flex align-items-center justify-content-between mt-4 pb-3"
                  >
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 trans_text"
                    >
                      Total
                    </p>
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 trans_text"
                    >
                      {showTotal ? `₹ ${amount}` : "0"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 trans_text"
                    >
                      Grand Total
                    </p>
                    <p
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "white",
                      }}
                      className="mb-0 trans_text"
                    >
                      {showTotal ? `₹ ${amount}` : "0"}
                    </p>
                  </div>
                  <div className="mt-auto">
                    <button
                      className="border-0 text-white px-3 py-2 w-100 trans_text"
                      style={{
                        backgroundColor: proceedToPayDisabled
                          ? "#989898"
                          : "rgba(255, 162, 1, 1)",
                        borderRadius: "5px",
                        fontFamily: "Montserrat",
                        fontWeight: "500",
                        fontSize: "14px",
                        cursor: proceedToPayDisabled
                          ? "not-allowed"
                          : "pointer",
                      }}
                      onClick={() => setIsModalOpen(true)}
                    >
                      Proceed to Pay
                    </button>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Payment Gateway Selection"
                style={{
                  content: {
                    top: "50%",
                    left: "50%",
                    right: "auto",
                    bottom: "auto",
                    marginRight: "-50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    maxWidth: "600px",
                    padding: "25px 30px",
                    backgroundColor: "#fff",
                    height: "180px",
                    zIndex: 1050,
                    borderRadius: "12px",
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                  },
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    zIndex: 1040,
                  },
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <h2
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "25px",
                      fontWeight: "500",
                    }}
                    className="mb-0"
                  >
                    Choose Payment Gateway
                  </h2>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    aria-label="Close Modal"
                  >
                    ✕
                  </button>
                </div>

                <div className="mt-4 pt-2">
                  {loading ? (
                    <img
                      src={loaderGif}
                      alt="Loading..."
                      style={{
                        width: "50px",
                        height: "50px",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  ) : (
                    <>
                      <label
                        style={{ display: "inline-block", marginRight: "20px" }}
                      >
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="airpay"
                          checked={selectedPaymentMethod === "airpay"}
                          onChange={() => handlePaymentSelection("airpay")}
                          style={{ display: "none" }}
                        />
                        <img
                          style={{
                            width: "100px",
                            cursor: "pointer",
                            pointerEvents: "all",
                          }}
                          onClick={() => handlePaymentSelection("airpay")}
                          src={airpay}
                          alt="airpay-logo"
                        />
                      </label>

                      <label style={{ display: "inline-block" }}>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="razorpay"
                          checked={selectedPaymentMethod === "razorpay"}
                          onChange={() => handlePaymentSelection("razorpay")}
                          style={{ display: "none" }}
                        />
                        <img
                          className="ms-4"
                          style={{
                            width: "140px",
                            cursor: "pointer",
                            pointerEvents: "all",
                          }}
                          onClick={() => handlePaymentSelection("razorpay")}
                          src={razorpay}
                          alt="razorpay-logo"
                        />
                      </label>
                    </>
                  )}
                </div>
              </Modal>
            </div>
            <div
              id="airpay-form"
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{ __html: airpayForm }}
            ></div>
          </>
        )}
      </div>
    </>
  );
};

// export default Payment;
const mapStateToProps = (state) => ({
  backPage: state.dashboard.backPage,
  merchant_id: state.user.username,
  amount: state.payment.amount,
  srNo: state.payment.srNo,
});
export default connect(mapStateToProps)(Payment);
