import React, { useMemo, useState } from "react";
import {
  broadBandPostpaid,
  cableTv,
  clubsAssociation,
  creditCard,
  eduFees,
  fastag,
  hospitalPathology,
  housingSociety,
  landlinePostpaid,
  mobRechg,
  municipalTax,
  ncmc,
  newLogo,
  nps,
  pipeBill,
  recurringDeposit,
  rent,
  serviceBackArrow,
  subsFees,
} from "../images";
import { changePage } from "../redux/slice/dashboardSlice";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import {
  aadharPayIcon,
  aadharSeedingIcon,
  accOpeningIcon,
  authIcon,
  balInquiryIcon,
  bankTransferIcon,
  busIcon,
  currentIcon,
  dthBillIcon,
  dthRechargeIcon,
  elecBillIcon,
  fastagRechargeIcon,
  flightIcon,
  gasIcon,
  hotelIcon,
  insuranceIcon,
  irctcIcon,
  miniStIcon,
  mobSeedingIcon,
  mobileRechargeIcon,
  rdIcon,
  savingIcon,
  scanIcon,
  taxIcon,
  teamDepIcon,
  telecomIcon,
  uniqueIcon,
  waterBillIcon,
  withdrawlIcon,
} from "../images";
import Header from "./Header";
import pages from "../pages";

const NotFound = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <h2>Coming Soon</h2>
    <p>The requested page is under construction and will be available soon.</p>
  </div>
);

const ServiceTabs = ({ page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { service } = location.state || {};

  const [selectedSubService, setSelectedSubService] = useState(service);
  const [showAll, setShowAll] = useState(false);

  // change page
  const changePageFunc = () => {
    dispatch(changePage({ page: "services" }));
    navigate("/");
  };

  const changePageFuncBack = () => {
    dispatch(changePage({ page: "home" }));
    navigate("/");
  };

  const subServices = {
    "Bank Correspondence Services": [
      { name: "Account Opening", img: accOpeningIcon, id: "accOpening" },
      { name: "Saving", img: savingIcon, id: "saving" },
      { name: "Current", img: currentIcon, id: "current" },
      { name: "Recurring Deposit", img: rdIcon, id: "rd" },
      { name: "Term Deposit", img: teamDepIcon, id: "teamDep" },
      { name: "Aadhaar Seeding", img: aadharSeedingIcon, id: "aadharSeeding" },
      { name: "Mobile Seeding", img: mobSeedingIcon, id: "mobSeeding" },
    ],
    "Aadhar Enabled Payment Services (AEPS)": [
      { name: "Withdrawal", img: withdrawlIcon, id: "aepsWithdrawal" },
      { name: "Mini Statement", img: miniStIcon, id: "aepsStatement" },
      { name: "Balance Enquiry", img: balInquiryIcon, id: "aepsBalance" },
      { name: "Aadhaar Pay", img: aadharPayIcon, id: "aepsAadharPay" },
    ],
    "Micro ATM": [
      { name: "Withdrawal", img: withdrawlIcon, id: "microWithdawal" },

      { name: "Balance Enquiry", img: balInquiryIcon, id: "microBalEnq" },
    ],
    "Domestic Money Transfer": [
      { name: "Bank Transfer", img: bankTransferIcon, id: "queryRemitter" },
    ],
    "Cash Management Services": [
      { name: "Pay Loan EMI", img: scanIcon, id: "emiQR" },
    ],
    "Bharat Bill Payment Services": [
      {
        name: "Mobile Recharge",
        img: mobRechg,
        id: "mobileRecharge",
      },
      { name: "Gas Bill", img: gasIcon, id: "bbpsGasBill" },
      { name: "Water Bill", img: waterBillIcon, id: "bbpsWaterBill" },
      { name: "DTH Bill", img: dthBillIcon, id: "bbpsDthBill" },
      { name: "Pipeline Gas Bill", img: pipeBill, id: "bbpsPipedGas" },
      { name: "FastTag Recharge", img: fastag, id: "bbpsFastag" },
      { name: "Education Fees", img: eduFees, id: "bbpsEduFees" },
      { name: "Housing Society", img: housingSociety, id: "bbpsHousing" },
      { name: "NCMC", img: ncmc, id: "bbpsNcmc" },
      { name: "Municipal Taxes", img: municipalTax, id: "bbpsMunTaxes" },
      { name: "Subscription Fees", img: subsFees, id: "bbpsSubFees" },
      { name: "NPS", img: nps, id: "bbpsNps" },
      { name: "Rent", img: rent, id: "bbpsRent" },
      { name: "Credit Cards", img: creditCard, id: "bbpsCreditCards" },
      {
        name: "Clubs & Associations",
        img: clubsAssociation,
        id: "bbpsClubAssoc",
      },
      {
        name: "Hospital & Pathology",
        img: hospitalPathology,
        id: "bbpsHospital",
      },
      {
        name: "Broadband Postpaid",
        img: broadBandPostpaid,
        id: "bbpsBroadband",
      },
      { name: "Recurring Deposit", img: recurringDeposit, id: "bbpsRd" },
      {
        name: "Landline Postpaid",
        img: landlinePostpaid,
        id: "bbpsLandlinePostpaid",
      },
      { name: "Cable TV", img: cableTv, id: "bbpsCableTv" },
    ],
    "Travel Services": [
      { name: "Hotel Booking", img: hotelIcon, id: "hotelBooking" },
      { name: "Flight Ticket Booking", img: flightIcon, id: "flightBooking" },
      { name: "Bus Ticket Booking", img: busIcon, id: "busBooking" },
      { name: "Train Ticket Booking", img: irctcIcon, id: "trainBooking" },
    ],
    "Financial Services": [
      {
        name: "Insurance Services",
        img: insuranceIcon,
        id: "insuranceServices",
      },
    ],
    "Government Services": [
      {
        name: "Pan Card",
        img: uniqueIcon,
        id: "panCard",
      },
      {
        name: "Government Scheme Application",
        img: taxIcon,
        id: "governmentSchemeApplication",
      },
    ],
    "Recharge Services": [
      {
        name: "Mobile Recharge",
        img: mobileRechargeIcon,
        id: "mobileRecharge",
      },
      { name: "DTH Recharge", img: dthRechargeIcon, id: "dthRecharge" },
      {
        name: "Fastag Recharge",
        img: fastagRechargeIcon,
        id: "fastagRecharge",
      },
    ],
    "Device Sales": [
      { name: "Printer", img: authIcon, id: "printer" },
      { name: "FP Device", img: authIcon, id: "fpDevice" },
      { name: "MATM", img: authIcon, id: "matm" },
    ],
  };

  const matchingSubServices = useMemo(() => {
    if (service) {
      for (const serviceName in subServices) {
        const subServiceArray = subServices[serviceName];
        const matchingSubService = subServiceArray.find(
          (subService) => subService.id === service.id
        );
        if (matchingSubService) {
          return subServiceArray;
        }
      }
    }
    return [];
  }, [service, subServices]);

  const PageComponent = useMemo(() => {
    if (selectedSubService && pages[selectedSubService.id]) {
      return pages[selectedSubService.id];
    }
    return null;
  }, [selectedSubService]);

  const displayedSubServices = useMemo(() => {
    const presentServices = matchingSubServices.slice(0, 7);
    if (
      selectedSubService &&
      !presentServices.some((service) => service.id === selectedSubService.id)
    ) {
      return [...presentServices, selectedSubService];
    }
    return presentServices;
  }, [matchingSubServices, selectedSubService]);

  return (
    <>
      {/* Header  */}
      <div
        style={{ backgroundColor: "#D9D9D9" }}
        className="px-2 py-2 d-flex justify-content-between"
      >
        <img
          onClick={changePageFuncBack}
          style={{
            width: "220px",
            cursor: "pointer",
            pointerEvents: "all",
            marginLeft: "20px",
            padding: "6px 0",
          }}
          src={newLogo}
          alt="logo"
        />
        <Header
          style={{
            width: "75%",
            backgroundColor: "#194280",
            borderRadius: "10px",
            color: "#194280",
            position: "sticky",
            padding: "10px 0px",
            top: "0",
          }}
        />
      </div>

      {/* Service Tabs  */}
      <div className="mb-2 mt-1 mx-5 px-2 d-flex align-items-center">
        <div
          className="mb-0 px-2 d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: "rgba(217, 217, 217, 1)",
            borderRadius: "5px",
            color: "white",
            height: "50px",
            cursor: "pointer",
            pointerEvents: "all",
          }}
          onClick={changePageFunc}
        >
          <img src={serviceBackArrow} alt="back" />
        </div>
        {matchingSubServices.length > 0 && (
          <div className="d-flex ms-2 flex-wrap text-center justify-content-center">
            {displayedSubServices.map((subService) => (
              <div
                key={subService.id || subService.name}
                style={{ cursor: "pointer", margin: "0 20px" }}
                className="d-flex mt-2 flex-column text-center position-relative "
                onClick={() => setSelectedSubService(subService)}
              >
                <img
                  style={{ width: "40px", margin: "6px auto 0 auto" }}
                  src={subService.img}
                  alt="icon"
                />
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                  className={`mb-0 login_text  ${
                    selectedSubService.id === subService.id
                      ? "selected_service"
                      : ""
                  } `}
                >
                  {subService.name}
                </p>
              </div>
            ))}
            {matchingSubServices.length > 7 && (
              <div
                className="py-2 px-3"
                style={{
                  margin: "10px 20px",
                  backgroundColor: "#d9d9d9",
                  borderRadius: "8px",
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#333",
                  alignSelf: "center",
                }}
                onClick={() => setShowAll(!showAll)}
              >
                More
              </div>
            )}
          </div>
        )}
      </div>
      {showAll && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "80%",
              maxHeight: "80%",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <IoCloseSharp
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                fontSize: "25px",
                color: "#194280",
              }}
              onClick={() => setShowAll(false)}
            />
            <div className="row">
              {matchingSubServices.map((subService) => (
                <div className="col-3">
                  <div
                    key={subService.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      margin: "10px",
                      padding: "10px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "8px",
                      cursor: "pointer",
                      transition: "transform 0.3s",
                    }}
                    onClick={() => {
                      setSelectedSubService(subService);
                      setShowAll(false);
                    }}
                  >
                    <img
                      src={subService.img}
                      alt={subService.name}
                      style={{
                        width: "50px",
                        height: "50px",
                        marginBottom: "10px",
                      }}
                    />
                    <span style={{ textAlign: "center" }}>
                      {subService.name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {PageComponent ? (
        <PageComponent
          serviceId={selectedSubService.id}
          serviceName={selectedSubService.name}
        />
      ) : (
        <NotFound />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  page: state.dashboard.page,
  pageTitle: state.dashboard.pageTitle,
});

export default connect(mapStateToProps)(ServiceTabs);
